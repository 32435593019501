<template>
  <div>
    <div class="wel_top">
      <van-image class="wel_log" :src="logo"></van-image>
      <span>瑞方人力</span>
    </div>
    <div class="wel_desc1">51入职，方便快捷 </div>
    <div class="wel_desc2">只需两步，帮您快速入职</div>
    <div v-if="isShow">
      <div class="flex_center">
        <van-image class="wel_img" :src="img2"></van-image>
      </div>
      <div class="wel_span wel_spaned">欢迎加入瑞方</div>
      <div class="userBtn_out">
        <van-button
          type="info"
          @click="toMine"
          size="large"
          >点击进入</van-button
        >
      </div>
    </div>
    <div v-else>
      <div class="flex_center">
        <van-image
          class="wel_img"
          :src="img1"
        ></van-image>
      </div>
      <div class="wel_span">您没有收到企业邀请入职 ，可填写信息，申请入职</div>
      <div class="userBtn_out">
        <van-button
          type="info"
          @click="toMyinfo"
          size="large"
          >点击进入</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      isShow: false,
      img1: require("../assets/images/welcome1.png"),
      img2: require("../assets/images/welcome2.png"),
      logo: require("../assets/images/logo.png"),
    };
  },
  components: {},
  mounted() {
    this.getPhotoInfo(); //获取个人信息
  },
  methods: {
    //获取个人信息
    getPhotoInfo() {
      let pid = localStorage.getItem("pid");
      this.$axios
        .get(
          "/tag/info?table=person&table2=person_salary&fieldone=id&fieldtwo=person_id&id= " +
            pid +
            '&fieldasname={"name":"username","card_code":"usercard_code","phone":"phone","address":"address","pic":"pic","data_status":"data_statusb"}'
        )
        .then((res) => {
          if (res.data.success) {
            let returnData = res.data.data;
            if (
              returnData.username &&
              returnData.usercard_code &&
              returnData.bank_account &&
              returnData.bank_title &&
              returnData.address &&
              returnData.pic.split(",")[0] &&
              returnData.pic.split(",")[1]
            ) {
              this.isShow=true;
            } else {
              this.isShow=false;
            }
          } else {
            this.$toast({ message: res.data.msg });
          }
        })
        .catch(() => {});
    },
    toMine(){
      this.$router.push({name:"mine"})
    },
    toMyinfo(){
      this.$router.push({name:"previousInfo"})
    },
  },
};
</script>
<style scoped>
.wel_top {
  margin-top: 1.5rem;
  margin-bottom:1rem;
  font-size: 1.5rem;
  color: #3478F7;
  letter-spacing:0.25rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wel_desc1,.wel_desc2{
  text-align: left;
  padding:1rem 4rem;
  box-sizing: border-box;
  color: #78A1F2;
}
.wel_desc2{
  text-align: right;
}
.flex_center {
  display: flex;
  justify-content: center;
}
.wel_log{
  width: 2rem;
  height: 2rem;
  margin-right: 2.5rem;
}
.wel_img {
  width: 100%;
  /* height: 17.5rem; */
}
.wel_span {
  text-align: center;
  color: rgba(51, 51, 51, 0.5);
  font-size: 1rem;
}
.userBtn {
  border: none !important;
  width: 100%;
  margin: 0 auto;
  margin-top: 1.25rem;
  border-radius: 0.3rem !important;
}

.wel_spaned{
  color: #2694FD;
}

.userBtn_out {
  width: calc(100% - 2rem);
  margin: 0 auto;
  margin-top: 2rem;
  margin-bottom:1rem;
}
.userBtn_out .van-button {
  font-size: 1rem;
  height: 3rem;
  border-radius: 0.5rem;
}
</style>

